import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import _ from 'lodash';
import qs from 'qs';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { ToastContainer, Flip } from 'react-toastify';
import moment from 'moment';
import BodyClassName from 'react-body-classname';
import * as Sentry from '@sentry/browser';
import DocumentTitle from 'react-document-title';
import Sidebar from 'react-sidebar';
import striptags from 'striptags';
import TagManager from 'react-gtm-module';
import 'unfetch/polyfill';
import { browserName } from 'react-device-detect';

import 'react-toastify/dist/ReactToastify.css';
import 'react-day-picker/lib/style.css';

import Button from 'components/Button';
import Topbar from 'components/Topbar';
import Modal from 'components/Modal';
import Loading from 'components/Loading';
import { checkToken, logout } from 'actions/auth';
import { toggleSidebar as toggleSidebarAction } from 'actions/sidebar';
import { fetchMemberships } from 'actions/memberships';
import { fetchInitialConfig } from 'actions/config';
import getAndSaveUtms from 'helpers/utm';
import { GOOGLE_TAG_MANAGER_KEY, KEY_JWT_TOKEN } from 'config';
import history, { getRedirectParam } from 'helpers/history';
import { loadDinamicScripts } from 'helpers/script';
import { retry } from 'helpers/app';

import ActivityRemovedPage from 'scenes/ActivityRemoved';
import Author from 'scenes/Author';
import Calendar from 'scenes/Calendar';
import ContactUs from 'scenes/ContactUs';
import ErrorNetwork from 'scenes/ErrorPage/ErrorNetwork';
import ErrorPage from 'scenes/ErrorPage';
import NewsletterSuccess from 'scenes/Newsletter/NewsletterSuccess';
import Podcast from 'scenes/Podcast';
import Pricing from 'scenes/Pricing';
import RecoverPassword, { ChangePassword } from 'scenes/Signin/RecoverPassword';
import ReferralProgramTermsConditions from 'scenes/ReferralProgramTermsConditions';
import Register from 'scenes/Register';
import RegisterSuccess from 'scenes/Register/RegisterSuccess';
import Signin from 'scenes/Signin';
import Unsubscribe from 'scenes/Unsubscribe';
import UpgradeBrowser from 'scenes/UpgradeBrowser';

import { AuthContext } from 'reducers/auth';
import { getRedirect } from 'helpers/redirects';
import { publicationUrls, sectionUrls } from 'helpers/langs_urls';

import './app.css';

const Home = lazy(() =>
  retry({
    fn: () => import('scenes/Home'),
    module: false,
    component: 'Home',
  })
);
const Newsletter = lazy(() =>
  retry({
    fn: () => import('scenes/Newsletter'),
    module: false,
    component: 'Newsletter',
  })
);
const Library = lazy(() =>
  retry({
    fn: () => import('scenes/Library'),
    module: false,
    component: 'Library',
  })
);
const CmeProgram = lazy(() =>
  retry({
    fn: () => import('scenes/CmeProgram'),
    module: false,
    component: 'CmeProgram',
  })
);
const Checkout = lazy(() =>
  retry({
    fn: () => import('scenes/Checkout'),
    module: false,
    component: 'Checkout',
  })
);
const SearchPage = lazy(() =>
  retry({
    fn: () => import('scenes/SearchPage'),
    module: false,
    component: 'SearchPage',
  })
);
const Test = lazy(() =>
  retry({
    fn: () => import('scenes/Publication/TestProxy'),
    module: false,
    component: 'Test',
  })
);
const Dashboard = lazy(() =>
  retry({
    fn: () => import('scenes/Dashboard'),
    module: false,
    component: 'Dashboard',
  })
);
const AboutUs = lazy(() =>
  retry({
    fn: () => import('scenes/AboutUs'),
    module: false,
    component: 'AboutUs',
  })
);
const HelpFaqs = lazy(() =>
  retry({
    fn: () => import('scenes/HelpFaqs'),
    module: false,
    component: 'HelpFaqs',
  })
);
const MyAccount = lazy(() =>
  retry({
    fn: () => import('scenes/MyAccount'),
    module: false,
    component: 'MyAccount',
  })
);
const PrivacyPolicy = lazy(() =>
  retry({
    fn: () => import('scenes/PrivacyPolicy'),
    module: false,
    component: 'PrivacyPolicy',
  })
);
const TermsConditions = lazy(() =>
  retry({
    fn: () => import('scenes/TermsConditions'),
    module: false,
    component: 'TermsConditions',
  })
);

const Publication = lazy(() =>
  retry({
    fn: () => import('scenes/Publication'),
    module: false,
    component: 'Publication',
  })
);

const Section = lazy(() =>
  retry({
    fn: () => import('scenes/Publication/SectionProxy'),
    module: false,
    component: 'Section',
  })
);

if (GOOGLE_TAG_MANAGER_KEY) {
  TagManager.initialize({
    gtmId: GOOGLE_TAG_MANAGER_KEY,
  });
}

if (window.location.host === 'cme.psychopharmacologyinstitute.com') {
  window.location = `https://psychopharmacologyinstitute.com${window.location.pathname}`;
}

const theme = {
  flexboxgrid: {
    gridSize: 12, // columns
    gutterWidth: 1.555556, // rem
    outerMargin: 1.555556, // rem
  },
  container: {
    sm: 46, // rem
    md: 61, // rem
    lg: 76, // rem
  },
};

class App extends React.Component {
  componentDidMount() {
    localStorage.removeItem('hideReferralBar');
    this.props.fetchConfig();
    this.props.fetchMemberships();
    const ppToken = localStorage.getItem(KEY_JWT_TOKEN);
    this.props.checkToken(ppToken);
    getAndSaveUtms();
    loadDinamicScripts(
      [
        {
          id: 'ze-snippet',
          src: 'https://static.zdassets.com/ekr/snippet.js?key=07ff8a89-3afe-4147-917d-ba5d059502bb',
        },
      ],
      10
    );
    this.removeLoader();
  }

  componentDidCatch(error, errorInfo) {
    if (process.env.REACT_APP_ENV === 'production') {
      Sentry.withScope((scope) => {
        Object.keys(errorInfo).forEach((key) => {
          scope.setExtra(key, errorInfo[key]);
        });
        scope.setTag('error_page', 'error-app');
        Sentry.captureException(error);
      });
    }
    history.push('/error-app');
  }

  removeLoader = () => {
    const elem = document.getElementById('divLoader');
    if (_.get(elem, 'style', null)) {
      elem.style.display = 'none';
    }
  };

  render() {
    if (browserName === 'IE') {
      history.push('/upgrade-browser');
    }

    const redirectTo = getRedirect(window.location.pathname, history);

    if (redirectTo === null) history.push('/error-404');

    const { auth, modals, publication, sidebar, toggleSidebar, i18n } = this.props;

    const s = qs.parse(window.location.search.replace('?', ''));
    const canonical =
      s.canonical ||
      `${window.location.protocol}//${window.location.hostname}${window.location.pathname}`;

    return (
      <AuthContext.Provider value={auth}>
        <ThemeProvider theme={theme}>
          <BodyClassName className="app">
            <Router history={history}>
              <Sidebar
                sidebar={<Topbar inSidebar />}
                open={sidebar}
                onSetOpen={toggleSidebar}
                rootClassName="main-sidebar"
                sidebarClassName="main-sidebar-block"
                overlayClassName="overlay-sidebar"
                contentClassName="content-sidebar"
              >
                <Helmet>
                  <link rel="canonical" href={canonical} />
                </Helmet>
                <ToastContainer
                  position="bottom-left"
                  autoClose={5000}
                  transition={Flip}
                  closeButton={
                    <Button link main color="white">
                      DISMISS
                    </Button>
                  }
                  hideProgressBar
                  closeOnClick
                  pauseOnHover
                />
                <Modal modals={modals} />
                <Suspense fallback={<Loading centered active />}>
                  <Switch>
                    <Route
                      exact
                      path="/"
                      render={(props) => (
                        <>
                          <Helmet>
                            <meta
                              name="description"
                              content="Psychopharmacology and Psychiatry CME program. Online updates for psychiatrists and other clinicians. Self Assessment credits available."
                            />
                          </Helmet>
                          <DocumentTitle title="Psychopharmacology Updates for Clinicians - Psychopharmacology Institute">
                            <Home {...props} />
                          </DocumentTitle>
                        </>
                      )}
                    />
                    <Route
                      path="/publication/:publicationId/test"
                      render={(props) => (
                        <BodyClassName className="test full-height">
                          <DocumentTitle
                            title={
                              publication.isFetching
                                ? 'Psychopharmacology Institute'
                                : `${
                                    publication.data.seoTitle
                                      ? publication.data.seoTitle
                                      : publication.data.title
                                  } Test - Psychopharmacology Institute`
                            }
                          >
                            <Test {...props} />
                          </DocumentTitle>
                        </BodyClassName>
                      )}
                    />
                    <Route
                      exact
                      path="/publication/:slug"
                      render={(props) => {
                        const { slug } = props.match.params;
                        const mappedSlugs = publicationUrls[slug] || {};
                        return (
                          <BodyClassName className="publication">
                            <>
                              {!publication.isFetching && (
                                <Helmet>
                                  <meta
                                    name="description"
                                    content={striptags(
                                      publication.data.excerpt || publication.data.description || ''
                                    )}
                                  />
                                  {Object.entries(mappedSlugs).map(([lang, mappedSlug]) => (
                                    <link
                                      key={lang}
                                      rel="alternate"
                                      href={`${mappedSlug}`}
                                      hrefLang={lang}
                                    />
                                  ))}
                                </Helmet>
                              )}
                              <DocumentTitle
                                title={
                                  publication.isFetching
                                    ? 'Psychopharmacology Institute'
                                    : `${
                                        publication.data.seoTitle
                                          ? publication.data.seoTitle
                                          : publication.data.title
                                      } - Psychopharmacology Institute`
                                }
                              >
                                <Publication {...props} />
                              </DocumentTitle>
                            </>
                          </BodyClassName>
                        );
                      }}
                    />
                    <Route
                      exact
                      path="/section/:slug"
                      render={(props) => {
                        const { slug } = props.match.params;
                        const mappedSlugs = sectionUrls[slug] || {};
                        return (
                          <BodyClassName className="section full-height">
                            <Section {...props} mappedSlugs={mappedSlugs} />
                          </BodyClassName>
                        );
                      }}
                    />
                    <Route
                      exact
                      path="/library"
                      render={(props) => (
                        <BodyClassName className="library">
                          <>
                            <Helmet>
                              <meta
                                name="description"
                                content="Library - Psychopharmacology Institute"
                              />
                            </Helmet>
                            <DocumentTitle title="Library - Psychopharmacology Institute">
                              <Library {...props} />
                            </DocumentTitle>
                          </>
                        </BodyClassName>
                      )}
                    />
                    <Route
                      exact
                      path="/podcasts"
                      render={(props) => (
                        <BodyClassName className="podcast">
                          <>
                            <Helmet>
                              <meta
                                name="description"
                                content="Psychiatry and psychopharmacology podcast. Download the episodes or get the transcript and PDF version from our website."
                              />
                            </Helmet>
                            <DocumentTitle title="Psychiatry and Psychopharmacology Podcast - Psychopharmacology Institute">
                              <Podcast {...props} />
                            </DocumentTitle>
                          </>
                        </BodyClassName>
                      )}
                    />
                    <Route
                      exact
                      path="/author/:slug"
                      render={(props) => (
                        <BodyClassName className="author">
                          <>
                            <Helmet>
                              <meta
                                name="description"
                                content="Author - Psychopharmacology Institute Library"
                              />
                            </Helmet>
                            <DocumentTitle title="Author - Psychopharmacology Institute">
                              <Author {...props} />
                            </DocumentTitle>
                          </>
                        </BodyClassName>
                      )}
                    />
                    <Redirect from="/pricing" to="/cme-program" exact />
                    <Route
                      exact
                      path="/pricing"
                      render={(props) => (
                        <BodyClassName className="pricing">
                          <>
                            <Helmet>
                              <meta
                                name="description"
                                content="Pricing - Psychopharmacology Institute"
                              />
                            </Helmet>
                            <DocumentTitle title="Pricing - Psychopharmacology Institute">
                              <Pricing {...props} />
                            </DocumentTitle>
                          </>
                        </BodyClassName>
                      )}
                    />
                    <Route
                      exact
                      path="/signin"
                      render={(props) => (
                        <BodyClassName className="siging full-height">
                          <>
                            <Helmet>
                              <meta
                                name="description"
                                content="SignIn - Psychopharmacology Institute"
                              />
                            </Helmet>
                            <DocumentTitle title="SignIn - Psychopharmacology Institute">
                              <Signin {...props} />
                            </DocumentTitle>
                          </>
                        </BodyClassName>
                      )}
                    />
                    <Route
                      exact
                      path="/recover-password"
                      render={(props) => (
                        <BodyClassName className="recover-password full-height">
                          <>
                            <Helmet>
                              <meta name="robots" content="noindex" />
                            </Helmet>
                            <DocumentTitle title="Recover Password - Psychopharmacology Institute">
                              <RecoverPassword {...props} />
                            </DocumentTitle>
                          </>
                        </BodyClassName>
                      )}
                    />
                    <Route
                      exact
                      path="/recover-password/change/:uid/:token"
                      render={(props) => (
                        <BodyClassName className="recover-password-change full-height">
                          <>
                            <Helmet>
                              <meta name="robots" content="noindex" />
                            </Helmet>
                            <DocumentTitle title="Change Password - Psychopharmacology Institute">
                              <ChangePassword {...props} />
                            </DocumentTitle>
                          </>
                        </BodyClassName>
                      )}
                    />
                    <Route
                      exact
                      path="/dashboard"
                      render={(props) => (
                        <BodyClassName className="dashboard">
                          <>
                            <Helmet>
                              <meta name="robots" content="noindex" />
                            </Helmet>
                            <DocumentTitle title="Dashboard - Psychopharmacology Institute">
                              <Dashboard {...props} />
                            </DocumentTitle>
                          </>
                        </BodyClassName>
                      )}
                    />
                    <Route
                      exact
                      path="/dashboard/:page"
                      render={(props) => (
                        <BodyClassName className={`dashboard ${props.match.params.page}`}>
                          <>
                            <Helmet>
                              <meta name="robots" content="noindex" />
                            </Helmet>
                            <DocumentTitle title="Dashboard - Psychopharmacology Institute">
                              <Dashboard {...props} />
                            </DocumentTitle>
                          </>
                        </BodyClassName>
                      )}
                    />
                    <Route
                      path="/myaccount"
                      render={(props) => (
                        <BodyClassName className="myaccount">
                          <>
                            <Helmet>
                              <meta name="robots" content="noindex" />
                            </Helmet>
                            <DocumentTitle title="My Account - Psychopharmacology Institute">
                              <MyAccount {...props} />
                            </DocumentTitle>
                          </>
                        </BodyClassName>
                      )}
                    />
                    <Route
                      exact
                      path="/logout"
                      render={() => {
                        logout();
                        window.location = getRedirectParam('/');
                        return null;
                      }}
                    />
                    <Route
                      exact
                      path="/register"
                      render={(props) => (
                        <BodyClassName className="register full-height">
                          <>
                            <Helmet>
                              <meta
                                name="description"
                                content="Register - Psychopharmacology Institute"
                              />
                            </Helmet>
                            <DocumentTitle title="Register - Psychopharmacology Institute">
                              <Register {...props} />
                            </DocumentTitle>
                          </>
                        </BodyClassName>
                      )}
                    />
                    <Route
                      exact
                      path="/newsletter"
                      render={(props) => (
                        <BodyClassName className="full-height">
                          <>
                            <Helmet>
                              <meta
                                name="description"
                                content="Newsletter - Psychopharmacology Institute"
                              />
                            </Helmet>
                            <DocumentTitle title="Newsletter - Psychopharmacology Institute">
                              <Newsletter {...props} />
                            </DocumentTitle>
                          </>
                        </BodyClassName>
                      )}
                    />
                    <Route
                      exact
                      path="/newsletter-success"
                      render={(props) => (
                        <BodyClassName className="register full-height">
                          <>
                            <Helmet>
                              <meta name="robots" content="noindex" />
                            </Helmet>
                            <DocumentTitle title="Newsletter Success - Psychopharmacology Institute">
                              <NewsletterSuccess {...props} />
                            </DocumentTitle>
                          </>
                        </BodyClassName>
                      )}
                    />
                    <Route
                      exact
                      path="/register-success"
                      render={(props) => (
                        <BodyClassName className="register full-height">
                          <>
                            <Helmet>
                              <meta name="robots" content="noindex" />
                            </Helmet>
                            <DocumentTitle title="Register Success - Psychopharmacology Institute">
                              <RegisterSuccess {...props} />
                            </DocumentTitle>
                          </>
                        </BodyClassName>
                      )}
                    />
                    <Route
                      exact
                      path="/about-us"
                      render={(props) => (
                        <BodyClassName className="about-us">
                          <>
                            <Helmet>
                              <meta
                                name="description"
                                content="About us - Psychopharmacology Institute"
                              />
                            </Helmet>
                            <DocumentTitle title="About us - Psychopharmacology Institute">
                              <AboutUs {...props} />
                            </DocumentTitle>
                          </>
                        </BodyClassName>
                      )}
                    />
                    <Route
                      exact
                      path="/checkout"
                      render={(props) => (
                        <BodyClassName className="checkout full-height">
                          <>
                            <Helmet>
                              <meta
                                name="description"
                                content="Checkout - Psychopharmacology Institute"
                              />
                            </Helmet>
                            <DocumentTitle title="Checkout - Psychopharmacology Institute">
                              <Checkout {...props} />
                            </DocumentTitle>
                          </>
                        </BodyClassName>
                      )}
                    />
                    <Route
                      exact
                      path="/terms-and-conditions"
                      render={(props) => (
                        <BodyClassName className="terms_conditions">
                          <>
                            <Helmet>
                              <meta
                                name="description"
                                content="Terms and Conditions - Psychopharmacology Institute"
                              />
                            </Helmet>
                            <DocumentTitle title="Terms and Conditions - Psychopharmacology Institute">
                              <TermsConditions {...props} />
                            </DocumentTitle>
                          </>
                        </BodyClassName>
                      )}
                    />
                    <Route
                      exact
                      path="/referral-program-terms-and-conditions"
                      render={(props) => (
                        <BodyClassName className="referral_program_terms_conditions">
                          <>
                            <Helmet>
                              <meta
                                name="description"
                                content=" Referral Program Terms and Conditions - Psychopharmacology Institute"
                              />
                            </Helmet>
                            <DocumentTitle title="Referral Program Terms and Conditions - Psychopharmacology Institute">
                              <ReferralProgramTermsConditions {...props} />
                            </DocumentTitle>
                          </>
                        </BodyClassName>
                      )}
                    />
                    <Route
                      exact
                      path="/cme-program"
                      render={(props) => (
                        <BodyClassName className="cme-program">
                          <>
                            <Helmet>
                              <meta
                                name="description"
                                content={_.get(i18n, 'data.CME_META_DESCRIPTION.text', '')}
                              />
                            </Helmet>
                            <DocumentTitle
                              title={`Psychiatry & Psychopharmacology CME ${moment().year()}- Psychopharmacology Institute`}
                            >
                              <CmeProgram {...props} />
                            </DocumentTitle>
                          </>
                        </BodyClassName>
                      )}
                    />
                    <Route
                      exact
                      path="/calendar"
                      render={(props) => (
                        <BodyClassName className="calendar">
                          <>
                            <Helmet>
                              <meta
                                name="description"
                                content="Calendar - Psychopharmacology Institute"
                              />
                            </Helmet>
                            <DocumentTitle title="Calendar - Psychopharmacology Institute">
                              <Calendar {...props} />
                            </DocumentTitle>
                          </>
                        </BodyClassName>
                      )}
                    />
                    <Route
                      exact
                      path="/privacy-policy"
                      render={(props) => (
                        <BodyClassName className="privacy-policy">
                          <>
                            <Helmet>
                              <meta
                                name="description"
                                content="Privacy Policy - Psychopharmacology Institute"
                              />
                            </Helmet>
                            <DocumentTitle title="Privacy Policy - Psychopharmacology Institute">
                              <PrivacyPolicy {...props} />
                            </DocumentTitle>
                          </>
                        </BodyClassName>
                      )}
                    />
                    <Route
                      exact
                      path="/help-faqs"
                      render={(props) => (
                        <BodyClassName className="help-faqs">
                          <>
                            <Helmet>
                              <meta
                                name="description"
                                content="Help and Faqs - Psychopharmacology Institute"
                              />
                            </Helmet>
                            <DocumentTitle title="Help and Faqs - Psychopharmacology Institute">
                              <HelpFaqs {...props} />
                            </DocumentTitle>
                          </>
                        </BodyClassName>
                      )}
                    />
                    <Route
                      exact
                      path="/contact-us"
                      render={(props) => (
                        <BodyClassName className="contact-us">
                          <>
                            <Helmet>
                              <meta
                                name="description"
                                content="Contact Us - Psychopharmacology Institute"
                              />
                            </Helmet>
                            <DocumentTitle title="Contact Us - Psychopharmacology Institute">
                              <ContactUs {...props} />
                            </DocumentTitle>
                          </>
                        </BodyClassName>
                      )}
                    />
                    <Route
                      exact
                      path="/upgrade-browser"
                      render={() => (
                        <BodyClassName>
                          <>
                            <Helmet>
                              <meta
                                name="description"
                                content="Upgrade Browser - Psychopharmacology Institute"
                              />
                            </Helmet>
                            <DocumentTitle title="Upgrade Browser - Psychopharmacology Institute">
                              <UpgradeBrowser />
                            </DocumentTitle>
                          </>
                        </BodyClassName>
                      )}
                    />
                    <Route
                      exact
                      path="/error-network"
                      render={(props) => {
                        return (
                          <BodyClassName className="e404 full-height">
                            <>
                              <Helmet>
                                <meta name="robots" content="noindex" />
                              </Helmet>
                              <DocumentTitle title="Error - Psychopharmacology Institute">
                                <ErrorNetwork {...props} />
                              </DocumentTitle>
                            </>
                          </BodyClassName>
                        );
                      }}
                    />
                    <Route
                      exact
                      path="/error-:status"
                      render={(props) => {
                        return (
                          <BodyClassName className="e404 full-height">
                            <>
                              <Helmet>
                                <meta name="robots" content="noindex" />
                              </Helmet>
                              <DocumentTitle title="Error - Psychopharmacology Institute">
                                <ErrorPage
                                  code={_.get(props, 'match.params.status', '')}
                                  {...props}
                                />
                              </DocumentTitle>
                            </>
                          </BodyClassName>
                        );
                      }}
                    />
                    <Route
                      exact
                      path="/load-error"
                      render={(props) => {
                        return (
                          <BodyClassName className="e404 full-height">
                            <>
                              <Helmet>
                                <meta name="robots" content="noindex" />
                              </Helmet>
                              <DocumentTitle title="Error - Psychopharmacology Institute">
                                <ErrorPage
                                  code={_.get(props, 'match.params.status', '')}
                                  {...props}
                                />
                              </DocumentTitle>
                            </>
                          </BodyClassName>
                        );
                      }}
                    />
                    <Route
                      exact
                      path="/activity-removed"
                      render={() => {
                        return (
                          <BodyClassName className="e404 full-height">
                            <>
                              <Helmet>
                                <meta name="robots" content="noindex" />
                              </Helmet>
                              <DocumentTitle title="Error - Psychopharmacology Institute">
                                <ActivityRemovedPage />
                              </DocumentTitle>
                            </>
                          </BodyClassName>
                        );
                      }}
                    />
                    <Route
                      exact
                      path="/unsubscribe"
                      render={(props) => (
                        <BodyClassName className="unsubscribe">
                          <>
                            <Helmet>
                              <meta
                                name="description"
                                content="Unsubscribe - Psychopharmacology Institute"
                              />
                            </Helmet>
                            <DocumentTitle title="Unsubscribe - Psychopharmacology Institute">
                              <Unsubscribe {...props} />
                            </DocumentTitle>
                          </>
                        </BodyClassName>
                      )}
                    />
                    <Route
                      exact
                      path="/search"
                      render={(props) => (
                        <BodyClassName className="search">
                          <>
                            <Helmet>
                              <meta
                                name="description"
                                content="Publications Search - Psychopharmacology Institute"
                              />
                              <meta name="robots" content="noindex, nofollow" />
                            </Helmet>
                            <DocumentTitle title="Publications Search - Psychopharmacology Institute">
                              <SearchPage {...props} />
                            </DocumentTitle>
                          </>
                        </BodyClassName>
                      )}
                    />
                    <Route
                      exact
                      path="/search/:category"
                      render={(props) => (
                        <BodyClassName className="search">
                          <>
                            <Helmet>
                              <meta
                                name="description"
                                content="Publications Search - Psychopharmacology Institute"
                              />
                              <meta name="robots" content="noindex, nofollow" />
                            </Helmet>
                            <DocumentTitle title="Publications Search - Psychopharmacology Institute">
                              <SearchPage {...props} />
                            </DocumentTitle>
                          </>
                        </BodyClassName>
                      )}
                    />
                    <Route>
                      <Redirect to="/error-404" />
                    </Route>
                  </Switch>
                </Suspense>
              </Sidebar>
            </Router>
          </BodyClassName>
        </ThemeProvider>
      </AuthContext.Provider>
    );
  }
}
App.propTypes = {
  fetchConfig: PropTypes.func.isRequired,
  fetchMemberships: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  modals: PropTypes.array.isRequired,
  publication: PropTypes.shape({
    isFetching: PropTypes.bool.isRequired,
    data: PropTypes.shape({
      excerpt: PropTypes.string,
      description: PropTypes.string,
      title: PropTypes.string,
      seoTitle: PropTypes.string,
    }),
  }).isRequired,
  sidebar: PropTypes.bool.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired,
  checkToken: PropTypes.func.isRequired,
};

export default connect(
  (store) => ({
    auth: store.auth,
    modals: store.modals,
    publication: store.publication,
    sidebar: store.sidebar.mainSidebar,
    i18n: store.i18n,
  }),
  (dispatch) => ({
    checkToken: bindActionCreators(checkToken, dispatch),
    fetchMemberships: bindActionCreators(fetchMemberships, dispatch),
    toggleSidebar: bindActionCreators(toggleSidebarAction, dispatch),
    fetchConfig: bindActionCreators(fetchInitialConfig, dispatch),
  })
)(App);
